<template>
  <div class="elements-item-quantity">
    <span>
      {{ $t('confirmation.quantity') }}
    </span>

    <!-- :disabled="ten_required"  NEED TO FIGURE THIS OUT -->
    <!-- NEED TO FIGURE OUT DEPOSITS -->

    <select
      id="itemQuantityDropdown"
      v-model="itemQty"
      class="select required inline quantity form-control"
      @change="emitPackageQty()"
    >
      <option
        v-for="n in inventoryOptions"
        :key="n"
        :value="n"
      >
        {{ n }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import cartApi from '../../cart_api'

export default {

  props: {
    candelete: {
      default: null,
      type: String
    },
    disablesubtract:{
      default: false,
      type: Boolean
    },
    maxqty: {
      default: 10,
      type: Number
    },
    minqty: {
      default: 1,
      type: Number
    },
    hotel: {
      default: false,
      type: Boolean
    },
    quantity: {
      default: null,
      type: Number
    },
    item: {
      default: () => {},
      type: Object

    },
    updating: {
      default: false,
      type: Boolean
    }
  },

  data(){
    return {
      itemQty: 1,
    }
  },

  computed: {

    inventoryOptions(){
      console.log(this.qtyRequirementExists)
      if(this.qtyRequirementExists && !this.hotel){
        return this.requiredQtyPossibilities
      }  else {
        return this.possibilities
      }
    },

    possibilities(){
      let array = []
      for (let i = this.minqty; i <=this.maxqty; i++) {
        array.push(i)
      }
      return array
    },

    requiredQtyPossibilities(){
      return Array.from({length: this.maxqty}, (_, i) => i + 1).filter( (qty) => {
        return qty % this.item.quantity_required == 0
      })
    },

    qtyRequirementExists(){
      return this.item && this.item.quantity_required
    },

    parkingPassItem(){
      if(this.item && this.parkingPass){
        return this.quantity >= this.item.parking_pass_max_qty
      } else {
        return false
      }
    },

    parkingPass(){
      return this.item && this.item.parking_pass==true
    },
    cartdisabled(){
      return this.$store.state.order.order.disabled_cart
    },

    order(){
      return this.$store.state.order.order
    },

    deposit_qty_match(){
      return (this.order && this.order.deposit_qty_exact_match ? 'hidden disable' : '')
    },

    ten_required(){
      return this.item && this.item.ten_required
    },

    emitingQty(){
      return this.item.quantity_required || 1
    },
  },

  watch: {
    item() {
      this.updateItemQty()
    }
  },

  created(){
    this.updateItemQty()
  },

  methods: {
    ...mapMutations('order',['updateLoading']),
    ...mapActions('order', ['getBackendOrder']),

    emitPackageQty() {
      this.updateLoading(true)
      let body = null
      if(this.hotel){

        body = JSON.stringify({room_quantity: this.itemQty})
      }else {
        body = JSON.stringify({quantity: this.itemQty})
      }
      cartApi.changeOrderItem(this.item.id, 'PUT', body).then(resp => {
        if(resp.errors){
          //show errors
          this.updateLoading(false)
        }
        else {
          this.getBackendOrder().then(() => {
            this.updateLoading(false)
          })
        }
      })
    },

    updateItemQty(){
      if(this.hotel){
        this.itemQty = this.item.room_quantity
      } else if(this.item.min_package_qty > this.itemQty){
        this.itemQty = this.minqty
      } else {
        this.itemQty = this.item.quantity
      }
    },
    
    decreaseQty() {
      if (this.disableSubtract) { return }
      this.updateLoading(true)

      if (this.quantity <= this.emitingQty) {
        this.$emit('delete-item')
      } else {
        this.$emit('update-qty', this.quantity - this.emitingQty)
        this.updateRoomQty('subtractRoom')
      }
    },

    increaseQty() {
      if (this.disableAdd) { return }
      this.updateLoading(true)
      this.$emit('update-qty', this.quantity + this.emitingQty)
      this.updateRoomQty('addRoom')
    },

    updateRoomQty(action){
      let addRoom = document.getElementById('addRoom')
      if (action ==  'addRoom'){
        if (addRoom && addRoom.classList.value.includes('disable')){
          addRoom.classList.remove('disable')
          this.updateLoading(false)
        }
      }
      else if (action == 'subtractRoom' && document.getElementById('numberOfRooms').textContent == this.quantity){
        let subtractRoom = document.getElementById('subtractRoom')
        subtractRoom.click()
        setTimeout( function(){
          addRoom.classList.add('disable')
          this.updateLoading(false)
        }, 500)
      }

    }
  }
}
</script>
