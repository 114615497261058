<template>
  <section
    v-if="item.price > 0 || !item.h4h_hotel_add_on"
    class="package-item"
  >
    <div v-if="showParkingPassText">
      <ParkingPass
        :item="item"
      />
    </div>
    <div
      v-else
      class="single-item"
    >
      <div class="desc-quantity-holder">
        <ItemDescription
          :itemid="item.id"
          :item="item"
          :name="item.package_display_name"
          :price="item.price_decorated"
          :eventname="item.event_display_name"
          :package-id="item.package_id"
          :candelete="cartdisabled ? 'false': 'true'"
          :product-names="item.product_names"
          :cartdisabled="cartdisabled"
        />
        <ItemQty
          :item="item"
          :quantity="item_quantity"
          :maxqty="maxPackageQty"
          :minqty="minPackageQty"
          :updating="updating"
          :candelete="cartdisabled ? 'false': 'true'"
          :cartdisabled="cartdisabled"
          @update-qty="updateItem"
          @delete-item="deleteItem"
        />
      </div>
      <div class="delete-holder">
        <!-- <span
        v-if="!cartdisabled"
        class="delete quantity-select"
        @click="deleteItem"
        /> -->

        <i
          v-if="!cartdisabled"
          class="ion ion-md-close-circle-outline"
          @click="deleteItem"
        />
      </div>
      <div
        v-if="ers"
        class="errors"
      >
        <span>{{ ers }}</span>
        <span
          class="delete"
          @click="ers = null"
        />
      </div>
    </div>
  </section>
</template>

<script>
import ItemDescription from './item_description'
import ItemQty from './item_quantity'
import PackageOnlyJs from '../package_only_item_shared_methods'
import ParkingPass from './parking_pass'

export default
Object.assign({}, PackageOnlyJs, {
  components: {
    ItemDescription,
    ParkingPass,
    ItemQty
  }
})
</script>
