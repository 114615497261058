<template>
  <section class="hotel-item">
    <ItemDescription
      :itemid="item.id"
      :name="hotel_display_name"
      :price="item.price_decorated"
      :eventname="item.event_display_name"
      :package-id="item.package_id"
      candelete="true"
    />

    <div
      v-if="ers"
      class="errors"
    >
      <span>{{ ers }}</span>
      <span
        class="delete"
        @click="ers = null"
      />
    </div>

    <ItemQty
      :item="item"
      :quantity="item.room_quantity"
      :maxqty="hotelMaxQty"
      :updating="updating"
      candelete="true"
      @update-qty="updateItem"
    />
  </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import Api from '../../cart_api'

import ItemDescription from './item_description'
import ItemQty from './item_quantity'

export default {
  components: {
    ItemDescription,
    ItemQty
  },

  props: {
    item: {
      default: () => {},
      type: Object
    }
  },

  data() {
    return {
      ers: null,
      updating: false
    }
  },

  computed: {
    hotel_display_name() {
      return `${this.item.package_display_name} -
                ${this.item.hotel_display_name}`
    },

    hotelMaxQty() {
      return Math.min(this.item.hotel_inventory_on_site, 10)
    },

    eventTransportationText() {
      return this.$t('cart.transportationText')
    }
  },

  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('order', ['updateLoading']),
    updateItem(newHotelQty) {
      this.ers = null
      this.updating = true
      let body = JSON.stringify({ order_item: { room_quantity: newHotelQty } })

      Api.changeOrderItem(this.item.id, 'PUT', body).then(() => {
        this.getBackendOrder().then( () => this.updateLoading(false) )
      })
    }
  }
}
</script>
