<template>
  <div class="item-description flex-grow">
    <a
      v-if="packageId"
      :href="packagePath"
      class="in-cart-package-link"
    >
      <span class="name flex-grow font-weight-600"> {{ name }}</span>
    </a>
    <span
      v-else
      class="name flex-grow font-weight-600"
    >
      {{ name }}
      <!-- <i
        v-if="order && order.order_type!='WebOrder'"
        class="fa fa-question-circle"
        @click="isModalVisible = true"
      /> -->
    </span>
    <div
      v-if="item && item.hotel_reservations && item.hotel_reservations.length > 0"
    >
      <div>
        {{ item.hotel_reservations[0].hotel_check_in_date }} - {{ item.hotel_reservations[0].hotel_check_out_date }}
      </div>
    </div>
    <div
      v-if="order && order.order_type!='WebOrder'"
      class="quote-products-modal"
    >
      <QuoteProductsModal
        v-show="isModalVisible"
        :product-names="productNames"
        :name="name"
        @close="closeModal"
      />
    </div>

    <div
      v-if="ers"
      class="errors"
    >
      <span>{{ ers }}</span>
      <span
        class="delete"
        @click="ers = null"
      />
    </div>
  </div>
</template>

<script>
/* global locale */
// import Api from '../../cart_api'
import QuoteProductsModal from './q2oo_modal'
import {mapActions, mapMutations} from 'vuex'

export default {

  components: {
    QuoteProductsModal
  },

  props: {
    candelete: {
      default: '',
      type: String
    },
    eventname: {
      default: null,
      type: String
    },
    itemid: {
      default: 0,
      type: Number
    },
    item: {
      default: () => {},
      type: Object
    },
    name: {
      default: null,
      type: String
    },
    price: {
      default: null,
      type: String
    },
    packageId: {
      default: 0,
      type: Number
    },
    productNames: {
      default: () => [],
      type: Array
    }
  },


  data() {
    return {
      ers: null,
      // itemQty: 1,
      isModalVisible: false,
      packagePath: `/${locale}/packages/${this.packageId}/path`
    }
  },

  computed: {
    updating(){
      return this.$store.state.order.loading
    },


    order(){
      return this.$store.state.order.order
    }
  },

  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('order', ['updateLoading']),


    closeModal() {
      this.isModalVisible = false
    }
  }
}
</script>
