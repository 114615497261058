import { mapActions, mapMutations } from 'vuex'
import Api from '../cart_api'

export default {
  computed: {
    order(){
      return this.$store.state.order.order
    },

    hotelEventName(){
      return this.order.ticket_and_hotel ? this.item.event_display_name : ''
    },

    hotel_display_name() {
      return this.item.hotel_display_name
    },

    h4hAddOn() {
      return this.$store.state.order.order.h4h_add_on
    },

    isContracted() {
      return this.item.contract_hotel_reservations[0] && this.item.contract_hotel_reservations[0].type == 'ContractHotelReservation'
    },

    assignCheckIn(){
      if(this.isContracted == true) {
        return this.item.contract_check_in
      } else {
        return this.item.hotel_reservations[0].hotel_check_in_date
      }
    },

    assignCheckOut(){
      if(this.isContracted == true) {
        return this.item.contract_check_out
      } else {
        return this.item.hotel_reservations[0].hotel_check_out_date
      }
    },

    maxHotelQty() {
      return Math.min(this.item.hotel_inventory_on_site, this.item.quantity, 10)
    },

    minHotelQty(){
      return Math.max( Math.ceil(this.item.quantity/3), 1)
    },

    cartdisabled(){
      return this.order.cart_disabled
    },

    maxPackageQty() {
      return Math.min(this.item.inventory_on_site, 10)
    },

    maxTransportationQty() {
      return Math.min(this.item.room_quantity * 2, 10)
    },

    transportationFee() {
      return this.$t('cart.eventTransportation')
    },

    transportationText() {
      return this.$t('cart.eventTransportationText')
    },

    eventTransportationText() {
      return this.$t('cart.transportationText')
    },

    updating(){
      return this.$store.state.order.loading
    }
  },

  data() {
    return {
      ers: null
    }
  },

  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('order',['updateLoading']),
    createPackageHashForUpdate(newPackageQty) {
      let itemHash = { quantity: newPackageQty }
      if (this.item.room_quantity > newPackageQty) {
        // Room Qty cannot be larger than Package Qty
        // You must update Room Qty with Package Qty when this happens
        itemHash = Object.assign( {}, itemHash, { room_quantity: newPackageQty } )
        this.ers = this.$t('roomQtyError')
      } else if(this.item.room_quantity < newPackageQty/3){
        itemHash = Object.assign( {}, itemHash, { room_quantity: Math.ceil(newPackageQty/3) } )
      }

      return itemHash
    },

    changeOrderItem(body) {
      Api.changeOrderItem(this.item.id, 'PUT', body).then(item => {
        if (item.errors) { this.ers = item.errors }
        this.getBackendOrder().then( () => this.updateLoading(false) )
      })
    },

    updateTransportation(newQty){
      let body = JSON.stringify({transportation: {transportation_qty: newQty}})
      Api.updateHotelTransportation(this.item.id, 'PUT', body).then(item => {
        if (item.errors) { this.ers = item.errors }
        this.getBackendOrder().then( () => this.updateLoading(false) )
      })
    },

    deleteItem() {
      this.ers = null
      this.updateLoading(true)
      Api.changeOrderItem(this.item.id, 'DELETE', null).then(item => {
        if (item.errors) { this.ers = item.errors }
        this.getBackendOrder().then( () =>  this.updateLoading(false) )
      })
    },

    updateHotel(newHotelQty) {
      let body = JSON.stringify({ order_item: { room_quantity: newHotelQty } })
      this.changeOrderItem(body).then( () => this.updateLoading(false))

    },

    updatePackage(newPackageQty) {
      let body = JSON.stringify({ order_item: this.createPackageHashForUpdate(newPackageQty) })
      this.changeOrderItem(body).then( () => this.updateLoading(false))
    }
  },

  props: ['item']
}
